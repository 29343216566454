import type { Ref } from 'vue'
import { ref } from 'vue'

const overlay = ref<boolean>(false)

export interface UseOverlay {
  overlay: Ref<boolean>
}

export function useOverlay (): UseOverlay {
  return { overlay }
}
